import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["dynamicFractionDigits", "minimumFractionDigits", "maximumFractionDigits", "noSymbol", "suffix"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { LISTING_TYPE, SERVICE_PACKAGE } from "./DixuConstants";
export const parseNumber = val => {
  if (val == null || val === '') {
    return null;
  }
  const valToParse = typeof val === 'string' ? val.replace(/[^\d.,-]/g, '') : val;
  return Number((valToParse || 0).toString().split(',').join('.')) || 0;
};
export const formatNumber = function (num) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  };
  if (!num && num !== 0) {
    return '';
  }
  if (num.toString().substr(-1) === '.') {
    return num.toString();
  }

  // Only use default options if the entire options object is not provided
  const formatOptions = {
    minimumFractionDigits: options.minimumFractionDigits || 0,
    maximumFractionDigits: options.maximumFractionDigits || 2
  };
  return [parseNumber(num).toLocaleString('fi', {
    minimumFractionDigits: formatOptions.minimumFractionDigits,
    maximumFractionDigits: formatOptions.maximumFractionDigits
  }).replace(',00', '')].filter(Boolean).join(' ');
};
export const formatPrice = function (price) {
  let _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
    {
      dynamicFractionDigits = true,
      minimumFractionDigits = 0,
      maximumFractionDigits = 2,
      noSymbol = false,
      suffix = ''
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  if (price == null || price === '') {
    return null;
  }
  let formatted = parseNumber(price)?.toLocaleString('fi', _objectSpread({
    style: 'currency',
    currency: 'eur',
    currencyDisplay: 'symbol',
    minimumFractionDigits: dynamicFractionDigits && price % 1 !== 0 ? maximumFractionDigits : minimumFractionDigits,
    maximumFractionDigits
  }, rest));
  if (noSymbol) {
    formatted = formatted?.replace(/(^[^\d.,]+)|(\s[^\d.,]+$)/g, '');
  }
  return `${formatted}${suffix}`;
};
export const formatPercentage = function (percentage) {
  let {
    minimumFractionDigits = 0,
    maximumFractionDigits = 0
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!isNaN(percentage)) {
    return Number(percentage).toLocaleString('fi', {
      style: 'percent',
      minimumFractionDigits,
      maximumFractionDigits
    });
  }
  return percentage;
};
export const getNumericPriceForCity = _ref2 => {
  let {
    type,
    servicePackage
  } = _ref2;
  if (servicePackage === SERVICE_PACKAGE.DIXU_EASY) {
    if (type === LISTING_TYPE.APARTMENT) {
      return 1795;
    }
    if (type === LISTING_TYPE.PROPERTY) {
      return 3095;
    }
  }
  if (servicePackage === SERVICE_PACKAGE.DIXU_PLUS) {
    if (type === LISTING_TYPE.APARTMENT) {
      return 5395;
    }
    if (type === LISTING_TYPE.PROPERTY) {
      return 8195;
    }
  }
  return null;
};
export const getDiscountedPriceForListing = _ref3 => {
  let {
    discount,
    listing
  } = _ref3;
  const price = getNumericPriceForCity(_objectSpread({}, listing));
  const discountAmount = getDiscountAmount({
    total: price,
    listing,
    discount: discount || listing?.coupon
  });
  return {
    originalPrice: formatPrice(price),
    originalPriceWithoutVat: formatPrice(price / 1.24),
    discountAmount: formatPrice(discountAmount / 100),
    price: formatPrice(price - discountAmount / 100),
    vat: formatPrice(price - price / 1.24)
  };
};
export const getDiscountAmount = _ref4 => {
  let {
    total,
    listing,
    discount
  } = _ref4;
  total = total || getNumericPriceForCity(_objectSpread({}, listing));
  if (!discount) return 0;
  if (discount?.discountType === 'FIXED') {
    return discount?.amount;
  }
  return total * discount?.amount / 100;
};