import humanize from '@jsdevtools/humanize-anything';
export const getTranslationOrOther = _ref => {
  let {
    value,
    customValue,
    translationKey,
    i18n,
    t
  } = _ref;
  if (!value) {
    return null;
  }
  if (value === 'OTHER' && customValue) {
    return customValue;
  }
  if (value === 'OTHER') {
    return '';
  }
  if (!i18n.exists(`${translationKey}${value}`)) {
    return value;
  }
  return t(`${translationKey}${value}`);
};
export const humanizeArray = _ref2 => {
  let {
    array = [],
    emptyAsNull = false,
    i18n
  } = _ref2;
  if (!array.length) {
    return emptyAsNull ? null : '';
  }
  const options = {
    conjunction: i18n.t(`common:And`).toLowerCase()
  };
  return humanize.list(array, options);
};
export const translateArray = _ref3 => {
  let {
    value,
    customValue,
    translationKey,
    i18n,
    t
  } = _ref3;
  if (!value?.length) {
    return undefined;
  }
  return value?.map(valueInArray => getTranslationOrOther({
    value: valueInArray,
    customValue,
    translationKey,
    i18n,
    t
  })).join(', ');
};
export const translateOrNull = (value, _ref4) => {
  let {
    t,
    i18n
  } = _ref4;
  if (!i18n.exists(value)) {
    return null;
  }
  return t(value);
};
const HUMANIZE_SANITIZE_REGEX = /,(?=[^,]*$)/;
export const translateArrayWithHumanize = _ref5 => {
  let {
    value,
    customValue,
    translationKey,
    i18n,
    t,
    emptyAsNull = false,
    params = {}
  } = _ref5;
  const {
    forceLowerCase
  } = params;
  if (!value?.length) {
    return emptyAsNull ? null : '';
  }
  const options = {
    conjunction: t(`common:And`).toLowerCase()
  };
  const values = value.map((valueInArray, index) => {
    const translation = getTranslationOrOther({
      value: valueInArray,
      customValue,
      translationKey,
      i18n,
      t
    });
    if (translation) {
      if (forceLowerCase) {
        return translation?.toLowerCase();
      }
      return index === 0 && translation ? translation : translation?.toLowerCase();
    }

    // There was no custom value
    if (valueInArray === 'OTHER') {
      return null;
    }

    // Never lowercase custom values
    return value;
  }).filter(Boolean);
  const humanized = humanize.list(values, options)?.replace(HUMANIZE_SANITIZE_REGEX, '');
  if (!humanized) {
    return emptyAsNull ? null : '';
  }
  return humanized;
};
export const getZoningStatus = _ref6 => {
  let {
    listing,
    t,
    i18n
  } = _ref6;
  const {
    zoningStatus,
    otherZoningStatus
  } = listing;
  if (!zoningStatus || !zoningStatus.length) {
    return null;
  }
  return translateArrayWithHumanize({
    value: zoningStatus,
    customValue: otherZoningStatus,
    translationKey: 'listing:ZoningStatusValues.',
    t,
    i18n
  });
};
export const translateBoolean = (value, _ref7) => {
  let {
    t,
    i18n
  } = _ref7;
  if (!i18n.exists(`common:BooleanTypes.${String(value)}`)) {
    return null;
  }
  return t(`common:BooleanTypes.${String(value)}`);
};